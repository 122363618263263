import styled from '@emotion/styled';
import { theme } from '../../styles';
import { keyframes } from '@emotion/core';
import Image from 'gatsby-image';

const reveal = keyframes`
0% {
  opacity: 0;
}

100% {
  opacity: 1;
}
`;

export const ProjectTilesContainer = styled.div`
  .display-block {
    display: block;
    animation: ${reveal} 1s linear;
  }

  .display-none {
    display: none;
  }

  .ProjectTile__Container {
    margin-bottom: 40px;

    @media (min-width: ${theme.breakpoints.md}) {
      margin-bottom: 72px;
    }

    .Photo {
      transition-property: transform;
      transition: 500ms ease-out;
      transform: scale(1.1);
    }

    &:hover {
      .Photo {
        transform: scale(1.3);
      }
      .PlusIcon {
        transform: rotate(180deg) scale(0.85);

        rect {
          fill: ${theme.colors.primaryred};
        }
      }
    }
  }

  &.ProjectTiles {
    @media (min-width: ${theme.breakpoints.md}) {
      min-height: 420px;
    }

    .ProjectTile__Container {
      border: 1px solid white;

      &:hover {
        color: ${theme.colors.primaryred};
        border: 1px solid ${theme.colors.primaryred};

        .ProjectTile__Plus {
          .cls-1 {
            fill: ${theme.colors.primaryred};
          }
        }

        .ProjectTile_Border {
          border-bottom: 1px solid ${theme.colors.primaryred} !important;

          :not(:last-of-type) {
            border-top: 1px solid ${theme.colors.primaryred} !important;
          }
        }
      }

      .ProjectTile__Plus {
        @media (min-width: ${theme.breakpoints.xxs}) {
          margin-left: 15px;
        }
      }
    }
  }

  &.Articles__ProjectTiles {
    .ProjectTile__Country-text {
      padding-left: 0;
      padding-right: 0;

      @media (min-width: ${theme.breakpoints.md}) {
        padding-bottom: 25px;
      }
    }

    .ProjectTile__Plus {
      @media (min-width: ${theme.breakpoints.md}) {
        margin-left: 0;
      }
    }

    .ProjectTile__Photo {
      margin: 0 auto;
    }

    .ProjectTilesImageContainer {
      margin-bottom: 0px;
    }

    .ProjectTile__Container {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(${theme.colors.primaryredrgba}, 0.4);
        opacity: 0;
        z-index: 1;
        mix-blend-mode: multiply;
        transition: opacity 300ms linear;
        pointer-events: none;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }
  }

  .ProjectTilesContainer__GridChild {
    @media (min-width: ${theme.breakpoints.md}) {
      margin-right: 45px;
    }

    &:nth-of-type(3n) {
      margin-right: 0px !important;
    }
  }
`;

export const ProjectTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: visible;
  border: 1px solid white;

  @media (min-width: ${theme.breakpoints.md}) {
    max-width: 100%;
  }

  .ProjectTile__Photo {
    margin: 0 auto;
    min-height: 158px;

    @media (min-width: ${theme.breakpoints.md}) {
      min-height: 218px;
    }
  }

  .ProjectTilesImageContainer {
    margin-bottom: 30px;
  }

  .ProjectTile__SinglePhoto {
    object-fit: contain;
  }

  .ProjectTile__Sections {
    @media (min-width: ${theme.breakpoints.md}) {
      &:first-of-type {
        min-height: 250px;
      }
    }
  }

  .ProjectTile_Border {
    border-bottom: 1px solid black;
    padding: 5px 10px 25px 10px;

    @media (min-width: ${theme.breakpoints.md}) {
      padding: 25px 20px 25px 10px;
    }
  }

  .ProjectTile__Country-text {
    line-height: 20px;
    padding: 12px 15px;
    text-transform: uppercase;
  }

  .ProjectTile__Title {
    font-family: ${theme.fontFamily.tertiary};
    font-size: ${theme.fonts.l};
    line-height: 1.2;
    font-weight: 500;
    padding: 20px 0px 10px;

    @media (min-width: ${theme.breakpoints.md}) {
      font-size: ${theme.scale(theme.fonts.l)};
      padding: 0 0 10px 0;
    }
  }

  .ProjectTile__Article-Title {
    line-height: 1.2;

    @media (min-width: ${theme.breakpoints.md}) {
      margin-top: 15px;
      min-height: 85px;
      display: flex;
      align-items: flex-start;
    }
  }

  .ProjectTile__Title-container {
    @media (min-width: ${theme.breakpoints.md}) {
      min-height: 206px;
    }
  }

  .ProjectTile__Body-Title {
    font-family: ${theme.fontFamily.primary};
    font-weight: bold;
    line-height: 24px;
  }

  .ProjectTile__Plus {
    margin: 15px 15px 15px 0;
    width: 26px;

    @media (min-width: ${theme.breakpoints.xxs}) {
      margin: 15px;
      margin-left: 0px;
    }

    .cls-1 {
      fill: black;
    }

    &:hover {
      .cls-1 {
        fill: ${theme.colors.primaryred};
      }
    }
  }
`;

export const ProjectTilesChild = styled.div`
  width: 100%;

  @media (min-width: ${theme.breakpoints.s}) {
    width: calc(50% - 15px);
  }

  @media (min-width: ${theme.breakpoints.m}) {
    width: calc(33.33% - 30px);
  }
`;

export const TeamContentContainer = styled.div`
  padding: 20px 0 40px;

  .ProjectTile__Title {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 21px;
    font-family: ${theme.fontFamily.tertiary};
    font-weight: 500;
  }

  .ProjectTile__Position {
    font-family: ${theme.fontFamily.tertiary};
    font-size: 16px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1.3;
    min-height: 55px;

    > * {
      margin: 0;
    }
  }

  .ProjectTile__Qualification {
    font-family: ${theme.fontFamily.tertiary};
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1.3;

    > * {
      margin: 0;
    }
  }
`;

export const TeamTileContainer = styled.div``;

export const ProjectTilesImage = styled(Image)``;

export const ProjectTilesImageContainer = styled.div`
  overflow: hidden;
`;
